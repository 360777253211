import { documentReady, getSearchBoxConfigFromUrl } from '@utils/toolbox';
import { SearchBox } from '@module/SearchBox/SearchBox';

documentReady(function () {
	window.searchBox = new SearchBox({
		id: 'abSearchBox',
		dateSelector: document.getElementById('dateselector'),
		config: getSearchBoxConfigFromUrl(js_params.urlVars),
		callbacks: {
			onPreviewReceived: function (instance, previewGroup) {
				if (previewGroup?.flights?.length) {
					instance.cancelSearch();
					location.href = instance.showresultURL;
				}
			}
		}
	});

	window.setTimeout(() => {
		if (typeof window.ga === 'function') {
			window.ga('send', 'pageview', 'Ooooops404?page=' + document.location.pathname + document.location.search + '&from=' + document.referrer);
		}
	});
});
